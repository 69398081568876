.section {
  // border-bottom: 1px solid var(--light);
  padding-top: 5px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  min-width: 960px;
  width: calc(100% - 24px); 
  min-height: calc(100vh - var(--header-height) - 24px);
  background-color: #fff;
}
